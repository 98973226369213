.proj-slider-feat {
  opacity: 0;
  filter: blur(4px);
  transition: .3s ease-in-out;
  visibility: hidden; }
  .proj-slider-feat__container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px; }
    @media (max-width: 767px) {
      .proj-slider-feat__container {
        width: 100%; } }
    .proj-slider-feat__container--wide {
      max-width: 2250px;
      width: 100%; }
      @media (max-width: 2559px) {
        .proj-slider-feat__container--wide {
          max-width: 1440px; } }
      @media (max-width: 1439px) {
        .proj-slider-feat__container--wide {
          max-width: 1140px; } }
      @media (max-width: 1199px) {
        .proj-slider-feat__container--wide {
          max-width: 960px; } }
      @media (max-width: 991px) {
        .proj-slider-feat__container--wide {
          max-width: 100%;
          width: 720px; } }
  .proj-slider-feat.visible {
    opacity: 1;
    filter: blur(0);
    transition: .3s ease-in-out;
    visibility: visible; }
  @media (max-width: 991px) {
    .proj-slider-feat {
      padding-left: 12px;
      padding-right: 12px; } }
  .proj-slider-feat__info-wrapper {
    height: 100%;
    opacity: 0;
    position: relative;
    transition: opacity .3s ease-in-out;
    visibility: hidden;
    z-index: 2; }
  .proj-slider-feat__info {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    position: relative; }
  .proj-slider-feat__bg {
    padding: 20px; }
    @media (max-width: 767px) {
      .proj-slider-feat__bg {
        padding: 15px; } }
  .proj-slider-feat__box:hover .proj-slider-feat__info-wrapper {
    opacity: 1;
    transition: opacity .3s ease-in-out;
    visibility: visible; }
  .proj-slider-feat__box-link {
    text-decoration: none; }
  .proj-slider-feat__desc {
    color: #1c1417;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin-top: 14px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .proj-slider-feat__desc {
        display: none; } }
    @media (min-width: 576px) and (max-width: 767px) {
      .proj-slider-feat__desc {
        margin-top: 8px; } }
  .proj-slider-feat__link {
    color: #DB3645;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    margin-top: auto;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .25s ease; }
    .proj-slider-feat__link:hover {
      color: #a71e2a;
      transition: color .25s ease; }
  .proj-slider-feat__subtitle {
    color: #1c1417;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 4px;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .proj-slider-feat__subtitle {
        font-size: 14px;
        line-height: 18px; } }
    @media (max-width: 767px) {
      .proj-slider-feat__subtitle {
        font-size: 12px;
        line-height: 14px; } }
  .proj-slider-feat__title {
    color: #1c1417;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    width: 100%; }
    .proj-slider-feat__title--main {
      bottom: 0;
      color: #FFFFFF;
      font-size: 24px;
      font-weight: 600;
      left: 0;
      line-height: 28px;
      padding: 20px 60px 20px 20px;
      position: absolute;
      z-index: 1; }
  .proj-slider-feat .slick-arrow {
    background: transparent;
    border-color: transparent;
    border-width: 0;
    color: transparent;
    cursor: pointer;
    font-size: 0;
    height: 30px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    z-index: 1; }
    .proj-slider-feat .slick-arrow:before {
      content: '';
      display: block;
      height: 30px;
      left: 0;
      position: absolute;
      top: 0;
      width: 30px; }
  .proj-slider-feat .slick-disabled {
    display: none !important; }
  .proj-slider-feat .slick-list {
    margin: -15px; }
  .proj-slider-feat .slick-next {
    background: url(../../images/arrows/icon_arrow_next_black.svg) center/contain no-repeat;
    right: -48px; }
    @media (max-width: 767px) {
      .proj-slider-feat .slick-next {
        right: -28.5px; } }
  .proj-slider-feat .slick-prev {
    background: url(../../images/arrows/icon_arrow_prev_black.svg) center/contain no-repeat;
    left: -48px; }
    @media (max-width: 767px) {
      .proj-slider-feat .slick-prev {
        left: -28.5px; } }
  .proj-slider-feat .slick-slide {
    margin: 15px; }
  .proj-slider-feat .slick-slider {
    position: relative; }
